import React from "react";
import {
  SEO,
  PageLayout,
  Background,
  BlogPostNavigation,
} from "@bluefin/components";
import { Grid, Header } from "semantic-ui-react";
import { graphql } from "gatsby";

import WebsiteLayout from "../components/WebsiteLayout.jsx";
import {
  getComponentFiles,
  getComponentContentNodeContent,
} from "../utils/utils";

export default class BlogPage extends React.PureComponent {
  render() {
    const { data } = this.props;
    const {
      fishermanBusiness,
      fishermanBusinessWebsitePage,
      allFishermanBusinessBlogPost,
    } = data;

    return (
      <WebsiteLayout>
        <SEO
          businessName={fishermanBusiness.name}
          pageName={fishermanBusinessWebsitePage.title}
          images={getComponentFiles({
            components: fishermanBusinessWebsitePage.components,
            componentName: "HeroElement",
            numToSelect: 1,
          })}
          ogUrl={"https://www.smugglerscove.net/blog/"}
        />
        <PageLayout className={"blog-page"} hero={false}>
          <Grid stackable={true} className={"blog-page-container"}>
            <Grid.Column
              width={16}
              textAlign={"center"}
              className={"header-column"}
            >
              <Header
                as={"h1"}
                content={getComponentContentNodeContent({
                  components: fishermanBusinessWebsitePage.components,
                  componentId: "blog_header",
                  defaultValue: "BLOG",
                })}
              />
            </Grid.Column>
            <Grid.Column width={16} className={"blog-navigation-column"}>
              <Background columns={1}>
                <Background.Pattern type={"CIRCLE"}>
                  <BlogPostNavigation
                    title={""}
                    description={""}
                    featuredPostId={null}
                    alwaysShowSearch={false}
                    columns={3}
                    pageSize={12}
                    tight={true}
                    posts={allFishermanBusinessBlogPost.nodes}
                  />
                </Background.Pattern>
              </Background>
            </Grid.Column>
          </Grid>
        </PageLayout>
      </WebsiteLayout>
    );
  }
}

export const query = graphql`
  query {
    fishermanBusiness {
      name
    }
    fishermanBusinessWebsitePage(pageType: { eq: "Blog" }) {
      title
      components {
        fastId
        componentIdentifier
        order
        files {
          file
        }
        contentNodes {
          content
        }
      }
    }
    allFishermanBusinessBlogPost {
      nodes {
        _id
        title
        slug
        author {
          firstName
          lastName
          avatar
          email
        }
        summary
        content
        heroImage
        gatsbyHeroImage {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        publishedAt
        heroImageAltText
      }
    }
  }
`;
